<template lang="html">
  <table class="table is-striped is-narrow"
         v-if="(selectedEntry.parameters && selectedEntry.parameters.length) || selectedEntry.requestBody">
    <thead>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Type</th>
        <th>Limits</th>
        <th>Location</th>
      </tr>
    </thead>

    <tbody>
<!--    TODO request body, delete?-->
<!--
      <tr v-if="selectedEntry.requestBody">
        <td>Payload</td>
        <td>Request body</td>
        <td v-if="!selectedEntry.requestBody.content"></td>
        <td v-if="selectedEntry.requestBody.content">
          <md-select v-model="selectedEntry.requestBody.selectedType">
            <md-option v-for="contentType in Object.keys(selectedEntry.requestBody.content)" :key="contentType" :value="contentType">{{contentType}}</md-option>
          </md-select>
        </td>
        <td v-if="!selectedEntry.requestBody.content || !selectedEntry.requestBody.content[selectedEntry.requestBody.selectedType].schema"></td>
        <td v-if="selectedEntry.requestBody.content && selectedEntry.requestBody.content[selectedEntry.requestBody.selectedType].schema" style="align-items: left;">
          <md-icon class="md-accent" @click.native="openSchemaDialog(selectedEntry.requestBody.content[selectedEntry.requestBody.selectedType].schema)" style="cursor:pointer" title="View schema">open_in_new</md-icon>
        </td>
        <td>body</td>
        <td>
          <md-checkbox v-model="selectedEntry.requestBody.required" disabled></md-checkbox>
        </td>
        <td>
          <md-icon class="md-accent" v-if="examples(selectedEntry.requestBody.content[selectedEntry.requestBody.selectedType])" @click.native="openExamplesDialog(examples(selectedEntry.requestBody.content[selectedEntry.requestBody.selectedType]))" style="cursor:pointer">open_in_new</md-icon>
        </td>
      </tr>
-->


      <tr v-for="(parameter, i) in selectedEntry.parameters" :key="i">
        <td :class="{'has-text-weight-semibold has-text-info':parameter.required}">{{parameter.name}}</td>
        <td v-html="marked(parameter.description||'')"></td>
<!--        Type-->
        <td>{{parameter.type}}</td>
<!--        Limits-->
<!--        maxlength, enum, minlength-->
        <td v-if="parameter.minLength || parameter.maxLength">
          {{parameter.minLength?'min '+ parameter.minLength+' ':'' + parameter.minLength?'max '+ parameter.maxLength:''}}
        </td>
        <td v-else-if="parameter.enum">{{(parameter.enum || []).join(', ')}}</td>
        <td v-else="">

        </td>
        <!--        Location-->
        <td>{{parameter.in}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import marked from 'marked'

export default {
  props: [ 'selectedEntry', 'openSchemaDialog', 'openExamplesDialog' ],
  methods: {
    marked,
    examples(content) {
      if (content.example) return {'Example': {summary: 'Default example', value: content.example}};
      if (content.examples) return content.examples;
      if (content.schema && content.schema.example) return {'Example': {summary: 'Schema example', value: content.schema.example}};
      return null
    }
  }
}
</script>

<style lang="css">
</style>

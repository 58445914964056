<template>
  <div></div>
</template>

<script>
import JSONFormatter from 'json-formatter-js'
global.JSONFormatter = JSONFormatter;
import JSONSchemaView from 'json-schema-view-js/dist/bundle.js'
// require('json-schema-view-js/dist/style.css');


export default {
  props: ['schema'],

  computed: {
    view: function() {
      let vs = new JSONSchemaView(this.schema, 5);
      this.$log.debug('{SchemaView} view:', vs.render(), {});
      return vs.render();
    }
  },

  mounted: function() {
    this.$el.appendChild(this.view);
  },

  watch: {
    view: function(newView, oldView) {
      // when it changes remove the old html
      while (this.$el.firstChild) {
        this.$el.removeChild(this.$el.firstChild);
      }
      // now provide the new
      this.$el.appendChild(newView);
    }
  },



}
</script>

<style lang="scss">
</style>

<template>
  <open-api v-if="jsonApi" :api="jsonApi" :bookname="bookname" :slug="slug"></open-api>
</template>

<script>
  // get some convenience functions e.g. displayDate and convertToHTML
  import misc from '../mixins/misc'

  import OpenApi from '../apiview/OpenApi'
  import jsonApi from '../apiview/swagger.json'


  export default {
    name: 'Api',
    mixins: [misc],
    props: {
      // No props, all from router path
    },
    data: function () {
      return {
        jsonApi: jsonApi
      }
    },
    created() {
      //save the route params to pass on to our components as props
      this.bookname = this.$route.params.bookname; //book to go back to
      this.slug = this.$route.params.slug; //slug in book to go back to
    },
    components: {
      OpenApi
    }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  /*@import "~bulma/sass/utilities/mixins";*/
  /*@import "@/scss/_bulmaOverridden";*/


</style>

<!--
Puts up a Bulma Tag colour coded according to the type of the REST action.
-->
<template lang="html">
  <span class="is-uppercase has-text-weight-semibold tag" :class="colour">{{action}}</span>
</template>

<script>
export default {
  name: 'action-tag',
  props: ['action'],
  computed: {
    colour() {
      switch (this.action) {
        case 'post':
          return 'is-info';
        case 'put':
          return 'is-warning has-text-white';
        case 'get':
          return 'is-success';
        case 'delete':
          return 'is-danger';
        default:
          return 'is-danger';
      }
    }
  }
}
</script>

<style lang="scss">
</style>

<!--These components are for OpenAPI/Swagger version 2 NOT 3-->
<template>
  <section class="section has-background-white">
    <div>
      <div class="container has-text-centered" >
        <h1 class="title is-size-2 has-text-weight-light is-spaced has-space-below">
          {{api.info.title}}
        </h1>
        <h2 class="subtitle is-size-4 has-dbl-space-below" v-if="api.info.description"
            v-html="marked(api.info.description||'')"></h2>
      </div>
    </div>

    <div class="columns">
      <div class="column is-narrow">
        <aside class="menu has-dbl-space-below">
          <p class="menu-label">
            Click to expand/select
          </p>
          <ul class="menu-list">
            <li v-for="(entries, tag) in tags" :key="tag" >
                <p class='menu-label hand' @click.self="tagsExpanded[tag] = !tagsExpanded[tag]">
                  <font-awesome-icon :icon="['fal', 'compress-alt']" size="1x" class="has-text-primary" v-show="tagsExpanded[tag]"/>
                  <font-awesome-icon :icon="['fal', 'expand-alt']" size="1x" class="has-text-primary" v-show="!tagsExpanded[tag]"/>
                  {{tag}}&nbsp;
                </p>
              <ul class="menu-list" v-show="tagsExpanded[tag]">
                <li v-for="(entry, i) in entries" :key="i" @click="select(entry)" style="cursor:pointer" class="has-space-below">
                  <action-tag :action="entry.method"></action-tag>&nbsp;
                  <span :class="{'is-active':selectedEntry === entry}"
                     v-html="entry.path"></span>
                </li>
              </ul>
            </li>
          </ul>
        </aside>

        <div class="has-text-left">
          <span class="icon has-text-primary"><font-awesome-icon :icon="['far', 'arrow-to-left']" transform="grow-2 down-2"/></span>
          <router-link
              :to="{ name:'book', params: { bookname: bookname, slug: slug }}"
              class="is-size-5">API Guide
          </router-link>
        </div>
      </div>


      <div class="column">
        <div v-if="!selectedEntry">
          <p>Select an entry on the left to see detailed information...</p>
          </div>
          <div v-if="selectedEntry">
            <h2 class="title is-size-4"><action-tag :action="selectedEntry.method"></action-tag>&nbsp;{{ (api.servers && api.servers.length ? api.servers[0].url : '') + selectedEntry.path}}</h2>
            <h2 class="subtitle is-size-5 ">{{selectedEntry.title || selectedEntry.summary}}</h2>
            <p class="has-space-below" v-if="selectedEntry.description" v-html="marked(selectedEntry.description||'')"></p>
            <h4 class="is-size-5" v-if="(selectedEntry.parameters && selectedEntry.parameters.length) || selectedEntry.requestBody">
              Parameters <span class="is-size-7">(<span class="has-text-weight-semibold has-text-info">like this</span> = required)</span></h4>
            <parameters-table :selectedEntry="selectedEntry" :openSchemaDialog="openSchemaDialog" :openExamplesDialog="openExamplesDialog" />
            <h4 class="is-size-5">Responses</h4>
            <responses-table :selectedEntry="selectedEntry" :openSchemaDialog="openSchemaDialog" :openExamplesDialog="openExamplesDialog" :openFieldsDialog="openFieldsDialog" />
          </div>
      </div>

    </div>


<!--
  <md-dialog ref="schemaDialog" class="schema-dialog">
    <md-dialog-title>Schema</md-dialog-title>

    <md-dialog-content>
      <md-tabs>
        <md-tab id="tree" md-label="Tree">
          <schema-view :schema="currentSchema"></schema-view>
        </md-tab>
        <md-tab id="raw" md-label="Raw">
          <pre>{{ stringify(currentSchema, null, 2)}}</pre>
        </md-tab>
      </md-tabs>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button @click.native="$refs.schemaDialog.close()">ok</md-button>
    </md-dialog-actions>
  </md-dialog>

  <md-dialog ref="examplesDialog" class="examples-dialog">
    <md-dialog-title>Examples</md-dialog-title>

    <md-dialog-content>
      <md-tabs>
        <md-tab v-for="(example, label) in currentExamples" :md-label="label">
          <h5>{{example.summary}}</h5>
          <pre>{{ stringify(example.value, null, 2)}}</pre>
        </md-tab>
      </md-tabs>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button @click.native="$refs.examplesDialog.close()">ok</md-button>
    </md-dialog-actions>
  </md-dialog>

  <md-dialog ref="fieldsDialog" class="fields-dialog">
    <md-dialog-title>Fields</md-dialog-title>

    <md-dialog-content>
       <md-table>
         <md-table-header>
           <md-table-row>
             <md-table-head>Name</md-table-head>
             <md-table-head>Description</md-table-head>
             <md-table-head>Type</md-table-head>
             <md-table-head>Values</md-table-head>
           </md-table-row>
         </md-table-header>

         <md-table-body>
           <md-table-row v-for="(field, name) in currentFields" :key="name">
             <md-table-cell>{{name}}</md-table-cell>
             <md-table-cell v-html="marked(field.description||'')"></md-table-cell>
             <md-table-cell v-if="field.schema.type !== 'array'">{{field.schema.type}}</md-table-cell>
             <md-table-cell v-if="field.schema.type === 'array'">{{field.schema.items.type}} array</md-table-cell>
             <md-table-cell v-if="field.schema.type !== 'array' && field.schema.enum">{{field.schema.enum.join(', ')}}</md-table-cell>
             <md-table-cell v-if="field.schema.type === 'array'">
               <div style="overflow-y:scroll;max-height:200px;">{{(field.schema.items.enum || []).join(', ')}}</div>
             </md-table-cell>
             <md-table-cell v-else />
           </md-table-row>
         </md-table-body>
       </md-table>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button @click.native="$refs.fieldsDialog.close()">ok</md-button>
    </md-dialog-actions>
  </md-dialog>
-->



  </section>
</template>


<script>
import Vue from 'vue'
import marked from 'marked'
import ActionTag from './ActionTag.vue'
// import ResponseDisplay from './ResponseDisplay.vue'
import ResponsesTable from './ResponsesTable.vue'
import ParametersTable from './ParametersTable.vue'
import SchemaView from './SchemaView.vue'
// import VueMaterial from 'vue-material'
import deref from 'json-schema-ref-parser'
import stringify from 'json-stringify-pretty-compact'


/* LOCAL methods
 * Tags management utils
 */

const defaultStyle = {
  query: 'form',
  path: 'simple',
  header: 'simple',
  cookie: 'form'
};

function processContent(contentType, api) {
  // Spec allow examples as an item or an array. In the API or in the schema
  // we always fall back on an array
  if (contentType.schema) {
    contentType.examples = contentType.examples || contentType.schema.examples
    contentType.example = contentType.example || contentType.schema.example
  }

  if (contentType.example) {
    contentType.examples = [contentType.example]
  }
}

async function getTags(api) {
  const derefAPI = await deref.dereference(api);
  const tags = {};
  const tagsExpanded = {};
  Object.keys(derefAPI.paths).forEach(path => {
    Object.keys(derefAPI.paths[path])
      .filter(method => ['get', 'put', 'post', 'delete', 'options', 'head', 'patch', 'trace'].indexOf(method.toLowerCase()) !== -1)
      .forEach(method => {
        const entry = derefAPI.paths[path][method];
        entry.method = method;
        entry.path = path;
        // Filling tags entries
        entry.tags = entry.tags || [];
        if (!entry.tags.length) {
          entry.tags.push('No category')
        }
        entry.tags.forEach(tag => {
          tags[tag] = tags[tag] || [];
          tags[tag].push(entry)
        });

        entry.parameters = entry.parameters || [];
        if (derefAPI.paths[path].parameters) {
          entry.parameters = derefAPI.paths[path].parameters.concat(entry.parameters)
        }
        if (entry.parameters) {
          entry.parameters.forEach(p => {
            p.style = p.style || defaultStyle[p.in];
            p.explode = p.explode || (p.style === 'form');
            p.schema = p.schema || { type: 'string' }
          })
        }
        if (entry.requestBody) {
          if (entry.requestBody.content) {
            Vue.set(entry.requestBody, 'selectedType', Object.keys(entry.requestBody.content)[0]);
            entry.requestBody.required = true;
            Object.values(entry.requestBody.content).forEach(contentType => processContent(contentType, api))
          }
        }

        // Some preprocessing with responses
        entry.responses = entry.responses || {};
        Object.values(entry.responses).forEach(response => {
          if (response.content) {
            // preselecting responses mime-type
            Vue.set(response, 'selectedType', Object.keys(response.content)[0]);
            Object.values(response.content).forEach(contentType => processContent(contentType, api))
          }
        })
      })
  });
  Object.keys(tags).forEach(tag => {
    tagsExpanded[tag] = false;
  });
  return {tags, tagsExpanded};
}


export default {
  name: 'open-api',
  components: {
    // RequestForm,
    ActionTag,
    ResponsesTable,
    ParametersTable,
    SchemaView
  },
  props: ['api', 'headers', 'queryParams','bookname','slug'], //todo ??headers, queryParams
  data: () => ({
    selectedEntry: null,
    currentSchema: ' ',
    currentExamples: {},
    currentFields: {},
    // currentRequest: {
    //   contentType: '',
    //   body: '',
    //   params: {}
    // },
    // currentResponse: null,
    tags: {},
    tagsExpanded: {}
  }),
  mounted: function() {
    // if (this.$refs.menu.$children.length) this.$refs.menu.$children[0].toggleExpandList()
  },
  created() {
    getTags(this.api).then(tagsProcessed => {
      this.tags = tagsProcessed.tags;
      this.tagsExpanded = tagsProcessed.tagsExpanded;
    });
  },
  methods: {
    marked,
    stringify,
    reset(entry) {
      const newParams = {};
      (entry.parameters || []).forEach(p => {
        newParams[p.name] = (p.in === 'query' && this.queryParams && this.queryParams[p.name]) || (p.in === 'header' && this.headers && this.headers[p.name]) || null;
        if (!newParams[p.name]) {
          if (p.schema && p.schema.enum) {
            newParams[p.name] = p.schema.enum[0]
          }
          if (p.schema && p.schema.type === 'array') {
            newParams[p.name] = []
          }
          if (p.example) {
            newParams[p.name] = p.example
          }
        }
      });
      // this.currentRequest.params = newParams;
      // if (entry.requestBody) {
      //   this.currentRequest.contentType = entry.requestBody.selectedType;
      //   const example = entry.requestBody.content[this.currentRequest.contentType].example;
      //   this.currentRequest.body = typeof example === 'string' ? example : stringify(example, null, 2);
      // }
    },
    select(entry) {
      this.$log.debug("{select} entry: ", entry);
      this.reset(entry);
      this.selectedEntry = entry;
    },
    openSchemaDialog(schema) {
      this.currentSchema = schema;
      this.$refs.schemaDialog.open();
    },
    openExamplesDialog(examples) {
      this.currentExamples = examples;
      this.$refs.examplesDialog.open();
    },
    openFieldsDialog(fields) {
      this.currentFields = fields;
      this.$refs.fieldsDialog.open();
    },
    request() {
      this.currentResponse = null
      fetch(this.currentRequest, this.selectedEntry, this.api).then(res => {
        this.currentResponse = res
      }, res => {
        this.currentResponse = res
      })
    }
  }
}


</script>

<style lang="css">
/*
  .openapi {
    position:relative;
    overflow-x:hidden;
    height:100%;
  }

  .openapi #request-form {
    padding: 16px;
  }

  .openapi .md-table .md-table-cell.md-has-action .md-table-cell-container {
    display: inherit;
  }

  .schema-dialog .md-dialog, .examples-dialog .md-dialog{
    min-width: 800px;
  }

  .openapi .entry-description {
    margin: 0;
  }
*/
</style>
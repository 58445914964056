<template lang="html">
  <table class="table is-striped is-narrow">
    <thead>
      <tr>
        <th>HTTP Code</th>
        <th>Response</th>
        <th>Details</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(response, code) in selectedEntry.responses" :key="code">
        <td>{{code}}</td>
        <td v-html="marked(response.description || '')"></td>
        <td ><schema-view :schema="response.schema"/></td>

<!--
        <td v-if="response.content && response.content[response.selectedType].schema">
          <md-icon class="md-accent" @click.native="openSchemaDialog(response.content[response.selectedType].schema)" style="cursor:pointer">open_in_new</md-icon>
        </td>
        <td v-if="!(response.content && response.content[response.selectedType].schema)"></td>

        <td v-if="response.headers">
          <md-icon class="md-accent" @click.native="openFieldsDialog(response.headers)" style="cursor:pointer">open_in_new</md-icon>
        </td>
        <td v-if="!response.headers"></td>
-->

        <td>
          <md-icon class="md-accent" v-if="examples(response)" @click.native="openExamplesDialog(examples(response))" style="cursor:pointer">open_in_new</md-icon>
        </td>

      </tr>
    </tbody>
  </table>
</template>

<script>
import marked from 'marked'
import SchemaView from './SchemaView'

export default {
  props: [ 'selectedEntry', 'openSchemaDialog', 'openExamplesDialog', 'openFieldsDialog'],
  components: {
    SchemaView
  },
  methods: {
    marked,
    examples(response) {
      this.$log.debug('{examples} response:', response, {});
      this.$log.debug('{examples} response.schema.type:', response.schema.type, {});
      const content = response.content && response.content[response.selectedType];
      if (content) {
        if (content.example) return {'Example': {summary: 'Default example', value: content.example}};
        if (content.examples) return content.examples;
        if (content.schema && content.schema.example) return {'Example': {summary: 'Schema example', value: content.schema.example}}
      }
      return null
    }
  }
}
</script>

